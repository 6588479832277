import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

import {graphql} from 'gatsby'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'


const PagePrivacy = props => {
  
  const { title, body } = props.data.content;

  
  return (
    <Layout {...props}>
      <Seo title={title} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header={title}
        />
      </Stack>
      <Stack>
        <Main>
          <ReactMarkdown children={body.data.body} remarkPlugins={[remarkGfm]} />
        </Main>
      </Stack>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    content: strapiPrivacy(locale: { eq: $language }) {
      title
      body {
        data {
          body
        }
      }
    }
  }`  

export default PagePrivacy
